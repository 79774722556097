import { useState } from "react"
import { useAsync } from "react-async"

import { GDAPI } from "../../../../lib/GDAPI"
import { Toggle } from "../../../common/Toggle"

export type PaymentSettingsProps = {}

export const PaymentSettings: React.FC<PaymentSettingsProps> = () => {
  const [isCashPaymentEnabled, setIsCashPaymentEnabled] = useState(false)

  const getCashPaymentEnabledRequest = useAsync({
    promiseFn: fetchCashPaymentEnabled,
    onResolve: setIsCashPaymentEnabled,
  })

  const updateCashPaymentEnabledRequest = useAsync({
    deferFn: updateCashPaymentEnabled,
    onResolve: getCashPaymentEnabledRequest.reload,
  })

  const isSaveButtonDisabled =
    getCashPaymentEnabledRequest.isPending ||
    updateCashPaymentEnabledRequest.isPending ||
    getCashPaymentEnabledRequest.data === isCashPaymentEnabled

  return (
    <div className="flex flex-col">
      <div className="flex flex-row items-center border-t border-b border-gray-200 py-4">
        <div className="flex-1">Enable cash payments?</div>

        <Toggle
          value={isCashPaymentEnabled}
          onChange={setIsCashPaymentEnabled}
          disabled={
            getCashPaymentEnabledRequest.isLoading ||
            updateCashPaymentEnabledRequest.isLoading
          }
        />
      </div>

      <button
        className={`my-8 sm:w-1/2 sm:self-center px-4 py-2 rounded-lg font-medium text-lg ${
          isSaveButtonDisabled
            ? `bg-gray-200 text-gray-500 opacity-75`
            : `bg-green-500 text-white`
        }`}
        onClick={() =>
          updateCashPaymentEnabledRequest.run(isCashPaymentEnabled)
        }
        disabled={isSaveButtonDisabled}
      >
        {updateCashPaymentEnabledRequest.isPending
          ? "Saving..."
          : "Save changes"}
      </button>
    </div>
  )
}

async function fetchCashPaymentEnabled(
  props: any,
  controller: AbortController
): Promise<boolean> {
  return GDAPI.getCashPaymentEnabled(controller)
}

async function updateCashPaymentEnabled(
  args: any[],
  props: any,
  controller: AbortController
): Promise<void> {
  return GDAPI.updateCashPaymentEnabled(args[0], controller)
}
