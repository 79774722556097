import { Switch } from "@headlessui/react"

export type ToggleProps = {
  value: boolean
  onChange: (value: boolean) => void
  disabled?: boolean
}

export const Toggle: React.FC<ToggleProps> = ({
  value,
  onChange,
  disabled,
}) => {
  return (
    <Switch.Group>
      <Switch
        as="div"
        checked={value}
        onChange={(checked: boolean) => {
          if (disabled) {
            return
          }

          onChange(checked)
        }}
        className={`
          ${value ? "bg-green-500" : "bg-gray-200"}
          relative inline-flex items-center h-8 rounded-full w-14 shadow cursor-pointer
        `}
      >
        <span
          className={`
            ${value ? "translate-x-7" : "translate-x-1"}
            inline-block w-6 h-6 bg-white rounded-full
            transform transition ease-in-out duration-200 shadow
          `}
        />
      </Switch>
    </Switch.Group>
  )
}
