import { useState } from "react"

import { TabBar, TabBarButton } from "../../common/tab-bar"
import { OpeningHoursSettings } from "./opening-hours/OpeningHoursSettings"
import { DeliveriesSettings } from "./deliveries/DeliveriesSettings"
import { CollectionsSettings } from "./collections/CollectionsSettings"
import { PaymentSettings } from "./payment/PaymentSettings"

type SettingsTab = "opening-hours" | "collections" | "deliveries" | "payment"

export const SettingsPage: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<SettingsTab>("opening-hours")

  return (
    <div className={styles.page}>
      <div className={styles.tabBar}>
        <TabBar>
          <TabBarButton
            onClick={() => setSelectedTab("opening-hours")}
            isActive={selectedTab === "opening-hours"}
          >
            Opening hours
          </TabBarButton>

          <TabBarButton
            onClick={() => setSelectedTab("collections")}
            isActive={selectedTab === "collections"}
          >
            Collections
          </TabBarButton>

          <TabBarButton
            onClick={() => setSelectedTab("deliveries")}
            isActive={selectedTab === "deliveries"}
          >
            Deliveries
          </TabBarButton>

          <TabBarButton
            onClick={() => setSelectedTab("payment")}
            isActive={selectedTab === "payment"}
          >
            Payment
          </TabBarButton>
        </TabBar>
      </div>

      <div className={styles.tabContainer}>
        <div className={styles.tabContent}>
          {selectedTab === "opening-hours" ? (
            <OpeningHoursSettings />
          ) : selectedTab === "deliveries" ? (
            <DeliveriesSettings />
          ) : selectedTab === "collections" ? (
            <CollectionsSettings />
          ) : (
            <PaymentSettings />
          )}
        </div>
      </div>
    </div>
  )
}

const styles = {
  page: "flex-grow flex flex-col bg-white overflow-y-hidden",
  tabBar: "flex flex-row items-center justify-center bg-white shadow z-10",
  tabContainer: "flex-1 flex flex-col w-full overflow-y-auto",
  tabContent: "w-full max-w-2xl self-center px-4 py-8",
}
