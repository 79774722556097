import React, { useCallback, useRef, useState } from "react"
import { useAsync } from "react-async"
import _ from "lodash"
import * as DateFns from "date-fns"
import { ArrowSmLeftIcon, SearchIcon } from "@heroicons/react/solid"
import { useHistory, useRouteMatch } from "react-router-dom"
import Loader from "react-loader-spinner"

import {
  BasketItem,
  GDAPI,
  OrderDetails,
  OrderSummary,
} from "../../../lib/GDAPI"
import { OrderHelper, OrderStatus } from "../../../lib/OrderHelper"
import { TextField } from "../../common/TextField"
import { IssueRefundModal } from "./modals/IssueRefundModal"
import { RefundDetails } from "../../../models/RefundDetails"
import { UnableToReverseTransferModal } from "./modals/UnableToReverseTransferModal"
import { FailedTransferReversalDetails } from "./FailedTransferReversalDetails"
import { PaginatedOrderSummariesList } from "./PaginatedOrderSummariesList"

export function OrdersPage() {
  const history = useHistory()
  const routeMatch = useRouteMatch<{ path: string }>("/orders/:path")
  const isSearching = routeMatch?.params.path === "search"

  const ordersCompletedRequest = useAsync({
    promiseFn: fetchOrdersCompleted,
  })

  const [orderSummaries, setOrderSummaries] = useState<OrderSummary[]>([])
  const [nextCursor, setNextCursor] = useState<string | null>(null)
  const [hasFetchedAllResults, setHasFetchedAllResults] = useState(false)

  const fetchPaginatedOrderSummariesRequest = useAsync({
    deferFn: fetchPaginatedOrderSummaries,
    onReject: () => history.push("/"),
    onResolve: (paginatedResults) => {
      setOrderSummaries((existingOrderSummaries) => [
        ...existingOrderSummaries,
        ...paginatedResults.results,
      ])

      if (!paginatedResults.nextCursor) {
        setHasFetchedAllResults(true)
      } else {
        setNextCursor(paginatedResults.nextCursor)
      }
    },
  })

  const onOrdersPageEndReached = useCallback(() => {
    if (fetchPaginatedOrderSummariesRequest.isPending || hasFetchedAllResults) {
      return
    }

    fetchPaginatedOrderSummariesRequest.run(nextCursor)
  }, [fetchPaginatedOrderSummariesRequest, hasFetchedAllResults, nextCursor])

  const [searchValue, setSearchValue] = useState("")
  const searchInput = useRef<HTMLInputElement>(null)
  const canSearch = searchValue.trim().length > 1

  const onSearchValueChanged = useCallback(
    (input: string) => {
      setSearchValue(input)
    },
    [setSearchValue]
  )

  const [searchedOrderSummaries, setSearchedOrderSummaries] = useState<
    OrderSummary[]
  >([])
  const [nextSearchCursor, setNextSearchCursor] = useState<string | null>(null)
  const [hasFetchedAllSearchResults, setHasFetchedAllSearchResults] =
    useState(false)

  const searchPaginatedOrderSummariesRequest = useAsync({
    deferFn: searchPaginatedOrderSummaries,
    onReject: () => history.push("/"),
    onResolve: (paginatedResults) => {
      setSearchedOrderSummaries((existingOrderSummaries) => [
        ...existingOrderSummaries,
        ...paginatedResults.results,
      ])

      if (!paginatedResults.nextCursor) {
        setHasFetchedAllSearchResults(true)
      } else {
        setNextSearchCursor(paginatedResults.nextCursor)
      }
    },
  })

  const onSearchedOrdersPageEndReached = useCallback(() => {
    if (
      searchPaginatedOrderSummariesRequest.isPending ||
      hasFetchedAllSearchResults ||
      searchValue.trim().length === 0 ||
      searchedOrderSummaries.length === 0
    ) {
      return
    }

    searchPaginatedOrderSummariesRequest.run(
      searchValue.trim(),
      nextSearchCursor
    )
  }, [
    searchPaginatedOrderSummariesRequest,
    hasFetchedAllSearchResults,
    searchValue,
    nextSearchCursor,
    searchedOrderSummaries,
  ])

  const onSearchPressed = useCallback(() => {
    setSearchedOrderSummaries([])
    setNextSearchCursor(null)

    searchPaginatedOrderSummariesRequest.run(searchValue.trim(), null)
  }, [
    setSearchedOrderSummaries,
    setNextSearchCursor,
    searchValue,
    searchPaginatedOrderSummariesRequest,
  ])

  const [selectedOrderId, setSelectedOrderId] = useState<string>()

  const fetchOrderDetailsRequest = useAsync({
    deferFn: fetchOrderDetails,
    onReject: () => history.push("/"),
  })

  const selectedOrder = selectedOrderId
    ? fetchOrderDetailsRequest.data
    : undefined

  const orderStatus =
    selectedOrder == null
      ? undefined
      : OrderHelper.getOrderStatus(selectedOrder)

  const [isRefundModalOpen, setRefundModalOpen] = useState(false)
  const [refundDetailsWithFailedTransfer, setRefundDetailsWithFailedTransfer] =
    useState<RefundDetails>()

  return (
    <div className="w-full flex flex-col sm:flex-row bg-gray-100 overflow-hidden">
      <div
        className={`bg-white overflow-hidden border-r border-gray-200 ${
          selectedOrder != null ? "hidden md:flex" : "flex"
        } flex-col w-full md:max-w-sm`}
      >
        <div className="flex flex-row items-center border-b border-gray-200 h-14 p-2">
          {isSearching ? (
            <>
              <button
                className="flex flex-row items-center justify-center p-2 bg-gray-200 rounded-full shadow mx-2"
                onClick={() => {
                  history.push("/orders")
                }}
              >
                <ArrowSmLeftIcon className="w-5 h-5 text-gray-800" />
              </button>

              <TextField
                name="Search"
                placeholder="Search for an address"
                type="string"
                value={searchValue}
                onValueChanged={onSearchValueChanged}
                ref={searchInput}
                hideLabel
                className="flex-1 mx-2"
                primary
                onSubmit={onSearchPressed}
                autoComplete="off"
              />

              {searchPaginatedOrderSummariesRequest.isPending ? (
                <div className="px-3">
                  <Loader type="Bars" color="#3B82F6" width={16} height={16} />
                </div>
              ) : (
                <button
                  className={`${
                    canSearch ? `bg-green-200` : `bg-gray-200`
                  } p-2 rounded-full shadow ml-1 flex flex-row items-center justify-center`}
                  onClick={onSearchPressed}
                  disabled={!canSearch}
                >
                  <SearchIcon className="h-5 w-5 text-green-800" />
                </button>
              )}
            </>
          ) : (
            <>
              <div className="flex-1 text-base font-medium text-black leading-none">
                Orders
              </div>

              {ordersCompletedRequest.data != null && (
                <div className="text-sm font-medium text-gray-500 leading-none">
                  {ordersCompletedRequest.data} completed
                </div>
              )}

              <button
                className="bg-blue-200 p-2 rounded-full shadow mx-2 flex flex-row items-center justify-center"
                onClick={() => history.push("/orders/search")}
              >
                <SearchIcon className="h-5 w-5 text-blue-800" />
              </button>
            </>
          )}
        </div>

        {isSearching ? (
          <PaginatedOrderSummariesList
            orderSummaries={searchedOrderSummaries}
            selectedOrderId={selectedOrderId}
            onOrderSummarySelected={(orderSummary) => {
              setSelectedOrderId(orderSummary.id)
              fetchOrderDetailsRequest.run(orderSummary.id)
            }}
            onPageEndReached={onSearchedOrdersPageEndReached}
            allResultsFetched={hasFetchedAllSearchResults}
            tag="search"
          />
        ) : (
          <PaginatedOrderSummariesList
            orderSummaries={orderSummaries}
            selectedOrderId={selectedOrderId}
            onOrderSummarySelected={(orderSummary) => {
              setSelectedOrderId(orderSummary.id)
              fetchOrderDetailsRequest.run(orderSummary.id)
            }}
            onPageEndReached={onOrdersPageEndReached}
            allResultsFetched={hasFetchedAllResults}
            tag="orders"
          />
        )}
      </div>

      {selectedOrder == null && (
        <div className="hidden md:flex flex-col items-center justify-center text-center text-gray-500 font-medium text-lg w-full">
          Select an order.
        </div>
      )}

      {selectedOrder != null && (
        <div className="bg-white flex-1 flex flex-col overflow-hidden w-full">
          <div className="flex flex-row items-center border-b border-gray-200 h-14 px-2 w-full">
            <div
              className="md:hidden mr-2 flex-shrink-0 flex flex-col items-center justify-center w-8 h-8 rounded-full bg-gray-200 text-gray-800 cursor-pointer"
              onClick={() => setSelectedOrderId(undefined)}
            >
              <ArrowSmLeftIcon className="w-5 h-5" />
            </div>

            <div className="flex-1 text-base font-medium text-black leading-none">
              {selectedOrder.id}
            </div>

            <div className="text-sm font-normal text-gray-800 leading-none text-right">
              {DateFns.format(
                new Date(selectedOrder.createdAt),
                "d/M/yy h:mm a"
              )}

              <br />

              {orderStatus === OrderStatus.Completed
                ? `Completed in ${DateFns.formatDistanceStrict(
                    new Date(selectedOrder.createdAt),
                    new Date(
                      selectedOrder.collectedByCustomerAt ??
                        selectedOrder.deliveredAt ??
                        ""
                    ),
                    { unit: "minute" }
                  )}`
                : orderStatus}
            </div>
          </div>

          <div className="flex-1 flex flex-col overflow-y-auto px-2 py-4 w-full">
            {selectedOrder.deliveryAddress != null ? (
              <>
                <div className="text-base font-medium leading-none">
                  Delivery address
                </div>

                <div className="text-base font-normal leading-none mt-1 mb-4">
                  {selectedOrder.deliveryAddress.line1},{" "}
                  {selectedOrder.deliveryAddress.postcode}
                </div>
              </>
            ) : null}

            <div className="text-base font-medium leading-none">Customer</div>

            <div className="text-base font-normal leading-none mt-1">
              {selectedOrder.customer.firstName}{" "}
              {selectedOrder.customer.lastName}
            </div>

            <div className="text-base font-medium leading-none mt-4">
              Contact number
            </div>

            <div className="text-base font-normal leading-none mt-1">
              {selectedOrder.customer.phoneNumber}
            </div>

            <div className="text-base font-medium leading-none mt-4">
              Payment method
            </div>

            <div className="text-base font-normal leading-none mt-1">
              {selectedOrder.isCashOrder ? "Cash" : "Card"}
            </div>

            {selectedOrder.note != null && (
              <>
                <div className="text-base font-medium leading-none mt-4">
                  Note from customer
                </div>

                <div className="text-base font-normal leading-tight mt-1">
                  {selectedOrder.note}
                </div>
              </>
            )}

            {selectedOrder.staffMember != null && (
              <>
                <div className="text-base font-medium leading-none mt-4">
                  Prepared by
                </div>

                <div className="text-base font-normal leading-none mt-1">
                  {selectedOrder.staffMember}
                </div>
              </>
            )}

            {selectedOrder.deliveryStaff != null && (
              <>
                <div className="text-base font-medium leading-none mt-4">
                  Delivered by
                </div>

                <div className="text-base font-normal leading-none mt-1">
                  {selectedOrder.deliveryStaff.firstName}
                </div>
              </>
            )}

            <div className="flex flex-row items-center mt-2">
              <div className="flex-1 text-base font-medium leading-none">
                Basket
              </div>

              {orderStatus != null &&
              [OrderStatus.Completed].includes(orderStatus) ? (
                <button
                  className={`px-4 py-2 rounded-lg font-medium text-base ${
                    false
                      ? `bg-gray-200 text-gray-500 opacity-75`
                      : `bg-red-600 text-white`
                  }`}
                  onClick={() => setRefundModalOpen(true)}
                >
                  Issue refund
                </button>
              ) : null}
            </div>

            <OrderBasketList order={selectedOrder} />

            <FailedTransferReversalDetails order={selectedOrder} />
          </div>
        </div>
      )}

      {isRefundModalOpen && selectedOrder != null && (
        <IssueRefundModal
          onClose={() => setRefundModalOpen(false)}
          onRefundIssued={(refundDetails) => {
            setRefundModalOpen(false)

            if (refundDetails.unableToReverseTransfer) {
              setRefundDetailsWithFailedTransfer(refundDetails)
            }

            fetchOrderDetailsRequest.reload()
          }}
          order={selectedOrder}
        />
      )}

      {refundDetailsWithFailedTransfer != null && selectedOrder != null && (
        <UnableToReverseTransferModal
          onClose={() => setRefundDetailsWithFailedTransfer(undefined)}
          refundDetails={refundDetailsWithFailedTransfer}
          order={selectedOrder}
        />
      )}
    </div>
  )
}

type OrderBasketItemProps = {
  item: BasketItem
  replaced: number
  refunded: number
}

const OrderBasketItem = React.memo(function OrderBasketItem({
  item,
  replaced,
  refunded,
}: OrderBasketItemProps) {
  return (
    <div className="flex flex-col py-3 px-2 border-b border-gray-200">
      <div className="flex flex-row items-center">
        <div className="text-lg text-black font-medium leading-none">
          {item.quantity}
        </div>
        <div className="text-base text-gray-800 flex-1 mx-2 leading-none">
          {item.product.name}
        </div>
        <div className="text-base text-gray-800 leading-none">
          £
          {(
            (item.selectedOptions != null
              ? item.product.price
              : OrderHelper.getItemTotal(item)) / 100
          ).toFixed(2)}
        </div>
      </div>
      {item.selectedOptions?.map((group, groupIndex) => {
        return (
          <div key={groupIndex} className="flex flex-col pl-4 mt-1">
            <div className="text-base font-medium">{group.name}</div>
            {group.included.map((option, optionIndex) => (
              <div
                key={optionIndex}
                className="flex flex-row items-center pl-4 mt-1"
              >
                <div className="text-base text-black font-medium leading-none">
                  {option.quantity}
                </div>
                <div className="text-base text-gray-800 flex-1 mx-2 leading-none">
                  {option.name}
                </div>
                <div className="text-base text-gray-800 leading-none">
                  £{((option.quantity * option.price) / 100).toFixed(2)}
                </div>
              </div>
            ))}
            {group.extras.length > 0 && (
              <>
                {group.included.length > 0 && (
                  <div className="text-base font-medium pl-4">Extras</div>
                )}
                {group.extras.map((option, optionIndex) => (
                  <div
                    key={optionIndex}
                    className="flex flex-row items-center pl-4 mt-1"
                  >
                    <div className="text-base text-black font-medium leading-none">
                      {option.quantity}
                    </div>
                    <div className="text-base text-gray-800 flex-1 mx-2 leading-none">
                      {option.name}
                    </div>
                    <div className="text-base text-gray-800 leading-none">
                      £{((option.quantity * option.price) / 100).toFixed(2)}
                    </div>
                  </div>
                ))}
              </>
            )}
          </div>
        )
      })}
      {replaced > 0 && (
        <div className="flex flex-row items-center mt-1">
          <div className="text-lg text-purple-600 font-medium leading-none">
            {replaced}
          </div>
          <div className="text-lg text-purple-600 font-medium flex-1 mx-2 leading-none">
            replaced
          </div>
        </div>
      )}
      {refunded > 0 && (
        <div className="flex flex-row items-center mt-1">
          <div className="text-lg text-red-600 font-medium leading-none">
            {refunded}
          </div>
          <div className="text-lg text-red-600 font-medium flex-1 mx-2 leading-none">
            refunded
          </div>
        </div>
      )}
    </div>
  )
},
_.isEqual)

type OrderBasketListProps = {
  order: OrderDetails
}

const OrderBasketList = React.memo(function OrderBasketList({
  order,
}: OrderBasketListProps) {
  const vivoBasketItems = order.basketItems
    .filter((item) => item.merchant == null)
    .map((item, index) => (
      <OrderBasketItem
        key={index}
        item={item}
        replaced={OrderHelper.getReplacedQuantity(item, order)}
        refunded={OrderHelper.getRefundedQuantity(item, order)}
      />
    ))

  const ionaBasketItems = order.basketItems
    .filter((item) => item.merchant != null)
    .map((item, index) => (
      <OrderBasketItem
        key={index}
        item={item}
        replaced={OrderHelper.getReplacedQuantity(item, order)}
        refunded={OrderHelper.getRefundedQuantity(item, order)}
      />
    ))

  const refundsAmount = OrderHelper.getRefundAmount(order)
  const wasDonationRefunded = order.cancelledAt != null
  const wasDeliveryFeeRefunded = order.refunds?.some(
    (refund) => refund.includesDeliveryFee || !refund.items
  )

  return (
    <div className="flex flex-col">
      {vivoBasketItems.length > 0 && (
        <>
          <div className="text-base text-black font-medium leading-none mt-4 mb-2">
            Vivo Spencer Road
          </div>
          {vivoBasketItems}
        </>
      )}
      {ionaBasketItems.length > 0 && (
        <>
          <div className="text-base text-black font-medium leading-none mt-4 mb-2">
            Iona House Off Licence
          </div>
          {ionaBasketItems}
        </>
      )}
      <div className="flex flex-row items-center px-2 mt-4">
        <div className="flex-1 text-base text-black leading-none">
          Basket total:
        </div>
        <div className="text-base text-black leading-none">
          £{(order.basketTotal / 100).toFixed(2)}
        </div>
      </div>

      {order.bagFee != null && order.bagFee > 0 ? (
        <div className="flex flex-row items-center px-2 mt-2">
          <div className="flex-1 text-base text-black leading-none">
            Bag fee:
          </div>
          <div className="text-base text-black leading-none">
            £{(order.bagFee / 100).toFixed(2)}
          </div>
        </div>
      ) : null}

      {order.donation != null && order.donation > 0 ? (
        <div className="flex flex-row items-center px-2 mt-2">
          <div className="flex-1 text-base text-black leading-none">
            Donation to Foyle Hospice
            {wasDonationRefunded ? (
              <span>
                {" "}
                {"("}
                <span className="text-base text-red-600 leading-none font-medium">
                  refunded
                </span>
                {")"}
              </span>
            ) : null}
            :
          </div>
          <div className="text-base text-black leading-none">
            £{(order.donation / 100).toFixed(2)}
          </div>
        </div>
      ) : null}

      {order.deliveryAddress != null ? (
        <div className="flex flex-row items-center px-2 mt-2">
          <div className="flex-1 text-base text-black leading-none">
            Delivery to {order.deliveryAddress.postcode}
            {wasDeliveryFeeRefunded ? (
              <span>
                {" "}
                {"("}
                <span className="text-base text-red-600 leading-none font-medium">
                  refunded
                </span>
                {")"}
              </span>
            ) : null}
            :
          </div>
          <div className="text-base text-black leading-none">
            £{(order.deliveryFee / 100).toFixed(2)}
          </div>
        </div>
      ) : null}

      {order.discount != null && (
        <div className="flex flex-row items-center px-2 mt-2">
          <div className="flex-1 text-base text-purple-600 font-medium leading-none">
            {order.discount.description} - {order.discount.percent}% off basket:
          </div>
          <div className="text-base text-purple-600 font-medium leading-none">
            -£{(OrderHelper.getDiscountAmount(order) / 100).toFixed(2)}
          </div>
        </div>
      )}
      {refundsAmount > 0 && (
        <div className="flex flex-row items-center px-2 mt-2">
          <div className="flex-1 text-base text-red-600 leading-none font-medium">
            Refunds:
          </div>
          <div className="text-base text-red-600 leading-none font-medium">
            -£{(refundsAmount / 100).toFixed(2)}
          </div>
        </div>
      )}
      <div className="flex flex-row items-center px-2 mt-2">
        <div className="flex-1 text-base text-black leading-none font-medium">
          Order total:
        </div>
        <div className="text-base text-black leading-none font-medium">
          £{(OrderHelper.getTotal(order) / 100).toFixed(2)}
        </div>
      </div>

      {!order.isCashOrder && (
        <div className="flex flex-row items-center px-2 mt-4">
          <div className="flex-1 text-base text-blue-600 leading-none font-medium">
            Stripe fee:
          </div>
          <div className="text-base text-blue-600 leading-none font-medium">
            £{(order.stripeFee / 100).toFixed(2)}
          </div>
        </div>
      )}

      {order.vivoSpencerRoadFee > 0 && (
        <>
          <div className="flex flex-row items-center px-2 mt-2">
            <div className="flex-1 text-base text-black leading-none">
              Vivo Spencer Road portion:
            </div>
            <div className="text-base text-black leading-none">
              £{(order.vivoSpencerRoadFee / 100).toFixed(2)}
            </div>
          </div>
        </>
      )}
      {order.ionaHouseFee > 0 && (
        <>
          <div className="flex flex-row items-center px-2 mt-2">
            <div className="flex-1 text-base text-black leading-none">
              Iona House Off Licence portion:
            </div>
            <div className="text-base text-black leading-none">
              £{(order.ionaHouseFee / 100).toFixed(2)}
            </div>
          </div>
        </>
      )}
    </div>
  )
},
_.isEqual)

async function fetchOrdersCompleted(
  props: unknown,
  controller: AbortController
): Promise<number> {
  return GDAPI.getOrdersCompleted(controller)
}

async function fetchPaginatedOrderSummaries(
  args: unknown[],
  props: unknown,
  controller: AbortController
): Promise<{ results: OrderSummary[]; nextCursor: string | null }> {
  const [cursor] = args as [string | null]
  return GDAPI.getPaginatedOrderSummaries(cursor, controller)
}

async function searchPaginatedOrderSummaries(
  args: unknown[],
  props: unknown,
  controller: AbortController
): Promise<{ results: OrderSummary[]; nextCursor: string | null }> {
  const [query, cursor] = args as [string, string | null]
  return GDAPI.searchPaginatedOrderSummaries(query, cursor, controller)
}

async function fetchOrderDetails(
  args: unknown[],
  props: unknown,
  controller: AbortController
): Promise<OrderDetails> {
  const [id] = args as [string]
  return GDAPI.getOrderDetails(id, controller)
}
