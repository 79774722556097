import type { History } from "history"
import { AppStateDispatch } from "../components/AppStateContextProvider"
import { DeliveryFeeSettings } from "../models/DeliveryFeeSettings"
import { OrderRefund } from "../models/OrderRefund"
import { RefundDetails } from "../models/RefundDetails"

const host = process.env.REACT_APP_GDAPI_HOST

export type TimeRange = { start: [number, number]; end: [number, number] }

export type ProductCategorySummary = {
  id: number
  name: string
  image: string | null
  isHidden: boolean
  products: number
  offers: number
  subcategories: number
  availableBetween?: TimeRange[]
  parentCategory: number | null
  excludedFromDiscount: boolean
  ageVerificationRequired: boolean
  isFeatured: boolean
  merchant: number | null
}

export type ProductOfferSummary = {
  quantity: number
  price: number
  isFeatured: boolean
}

export type ProductOption = {
  id?: string
  name: string
  price: number
  max: number
}

export type ProductOptionGroup = {
  id?: string
  name: string
  options: ProductOption[]
  included: number
  extras: number
}

export type Product = {
  id: number
  category: number
  name: string
  price: number
  image: string | null
  outOfStockAt: string | null
  offer: ProductOfferSummary | null
  barcode: string | null
  maxQuantity: number | null
  options: ProductOptionGroup[] | null
}

export type ProductCategory = {
  id: number
  name: string
  image: string | null
  isHidden: boolean
  parentCategory: ProductCategorySummary | null
  subcategories: ProductCategorySummary[]
  products: Product[]
  availableBetween?: TimeRange[]
  merchant: number | null
}

export type ProductCategoryListItem = {
  id: number
  name: string
  subcategories: ProductCategoryListItem[]
}

export type BasketItem = {
  product: {
    id: number
    name: string
    price: number
    offer?: {
      quantity: number
      price: number
    }
    category: string
  }
  quantity: number
  excludedFromDiscount?: boolean
  ageVerificationRequired?: boolean
  selectedOptions?: SelectedOptions[]
  ref?: string
  merchant?: number
}

export type BasketSubstitutions = {
  id: number
  quantity: number
}

export type RefundSummary = {
  createdAt: string
  amount: number
  reason?: string
  items?: { id: number; quantity: number; ref?: string }[]
  includesDeliveryFee?: boolean
  unableToReverseTransfer?: boolean
}

export type Address = {
  latitude: number
  longitude: number
  line1: string
  townOrCity: string
  postcode: string
}

export type OrderDetails = {
  id: string
  createdAt: string
  basketItems: BasketItem[]
  basketSubstitutions?: BasketSubstitutions[]
  refunds?: RefundSummary[]
  basketTotal: number
  deliveryAddress?: Address
  deliveryFee: number
  acceptedAt?: string
  cancelledAt?: string
  readyAt?: string
  collectedAt?: string
  outForDeliveryAt?: string
  deliveredAt?: string
  note?: string
  deliveryStaff?: { firstName: string; lastName: string }
  discount?: { description: string; percent: number }
  customer: { firstName: string; lastName: string; phoneNumber: string }
  stripeFee: number
  vivoSpencerRoadFee: number
  ionaHouseFee: number
  donation?: number
  staffMember?: string
  collectedByCustomerAt?: string
  bagFee?: number
  isCashOrder?: boolean
}

export type OrderSummary = {
  id: string
  createdAt: string
  deliveryAddress?: string
  total: number
  status: string
}

export type SelectedOptions = {
  name: string
  included: SelectedProductOption[]
  extras: SelectedProductOption[]
}

export type SelectedProductOption = {
  name: string
  quantity: number
  price: number
}

export interface RevenueSummaryDetail {
  count: number
  total: number
  cardCount?: number
  cardTotal?: number
  cashCount?: number
  cashTotal?: number
}

export interface DriverRevenueSummary {
  name: string
  deliveries: RevenueSummaryDetail
}

export interface ShopRevenueSummary {
  orders: RevenueSummaryDetail
  discounts: RevenueSummaryDetail
  refunds: RevenueSummaryDetail
  fees: number
  bagFees?: number
}

export interface RevenueSummary {
  vivoSpencerRoad: ShopRevenueSummary
  ionaHouse: ShopRevenueSummary
  donations: RevenueSummaryDetail
  drivers: DriverRevenueSummary[]
  deliveries: RevenueSummaryDetail
}

export interface DailyRevenueSummary extends RevenueSummary {
  date: string
}

export interface WeeklyRevenueSummary extends RevenueSummary {
  start: string
  end: string
}

export interface MonthlyRevenueSummary extends RevenueSummary {
  month: string
}

export interface MerchantOnboardingStatus {
  detailsSubmitted: boolean
  chargesEnabled: boolean
  payoutsEnabled: boolean
}

export interface MerchantSummary {
  id: number
  name: string
}

export type DeliveryStaffMember = {
  id: number
  name: string
  phoneNumber: string
}

export type CustomerSearchResult = {
  id: number
  name: string
  phoneNumber: string
}

export type ScheduledOpeningHoursChange = {
  date: string
  openingHours: TimeRange
}

export class GDAPI {
  static get authToken(): string | null {
    return localStorage.getItem("sid")
  }

  static set authToken(token: string | null) {
    if (token === null) {
      localStorage.removeItem("sid")
    } else {
      localStorage.setItem("sid", token)
    }
  }

  static get accountType(): "admin" | "merchant" | null {
    const type = localStorage.getItem("account_type") as
      | "admin"
      | "merchant"
      | null

    if (type == null && this.authToken != null) {
      this.accountType = "admin"
      return "admin"
    }

    return type
  }

  static set accountType(type: "admin" | "merchant" | null) {
    if (type === null) {
      localStorage.removeItem("account_type")
    } else {
      localStorage.setItem("account_type", type)
    }
  }

  static async logIn(
    args: any[],
    props: any,
    controller: AbortController
  ): Promise<void> {
    const { dispatch, history } = props as {
      dispatch: AppStateDispatch
      history: History
    }

    const response = await fetch(`${host}/manage/sessions`, {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(args[0]),
      signal: controller.signal,
    })

    if (!response.ok) {
      if (response.status === 403) {
        throw new Error("Invalid email address or password.")
      }

      const message = await response.text()
      throw new Error(message)
    }

    const body: { sid: string; t: number } = await response.json()

    dispatch({ type: "setAuthToken", authToken: body.sid })
    dispatch({
      type: "setAccountType",
      accountType: body.t === 1 ? "admin" : "merchant",
    })

    if (body.t === 1) {
      history.push("/products")
    } else {
      history.push("/merchants/onboarding")
    }
  }

  static async getCategories(
    controller: AbortController
  ): Promise<ProductCategorySummary[]> {
    const response = await fetch(`${host}/admin/product-categories`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return await response.json()
  }

  static async getCategoryList(
    controller: AbortController
  ): Promise<ProductCategoryListItem[]> {
    const response = await fetch(`${host}/admin/category-list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return await response.json()
  }

  static async createProductCategory(
    args: any[],
    props: any,
    controller: AbortController
  ): Promise<ProductCategorySummary> {
    const response = await fetch(`${host}/admin/product-categories`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      body: JSON.stringify(args[0]),
      signal: controller.signal,
    })

    if (!response.ok) {
      throw new Error("Something went wrong, please try again")
    }

    return await response.json()
  }

  static async getProductCategory(
    id: string,
    controller: AbortController
  ): Promise<ProductCategory> {
    const response = await fetch(`${host}/admin/product-categories/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return await response.json()
  }

  static async updateProductCategory(
    args: any[],
    props: any,
    controller: AbortController
  ): Promise<ProductCategorySummary> {
    const [data, id] = args as [Partial<ProductCategorySummary>, number]

    const response = await fetch(`${host}/admin/product-categories/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      body: JSON.stringify(data),
      signal: controller.signal,
    })

    if (!response.ok) {
      throw new Error("Something went wrong, please try again")
    }

    return await response.json()
  }

  static async deleteProductCategory(
    args: any[],
    props: any,
    controller: AbortController
  ): Promise<void> {
    const [id] = args as [number]

    const response = await fetch(`${host}/admin/product-categories/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      throw new Error("Something went wrong, please try again")
    }
  }

  static async uploadImage(
    args: any[],
    props: any,
    controller: AbortController
  ): Promise<string> {
    const [file] = args as [File]

    const formData = new FormData()
    formData.append("image", file)

    const response = await fetch(`${host}/admin/images`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      body: formData,
      signal: controller.signal,
    })

    if (!response.ok) {
      throw new Error("Something went wrong, please try again")
    }

    const body = await response.json()
    return body.url
  }

  static async createProduct(
    args: any[],
    props: any,
    controller: AbortController
  ): Promise<Product> {
    const response = await fetch(`${host}/admin/products`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      body: JSON.stringify(args[0]),
      signal: controller.signal,
    })

    if (!response.ok) {
      throw new Error("Something went wrong, please try again")
    }

    return await response.json()
  }

  static async updateProduct(
    args: any[],
    props: any,
    controller: AbortController
  ): Promise<Product> {
    const [data, id] = args as [Partial<ProductCategorySummary>, number]

    const response = await fetch(`${host}/admin/products/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      body: JSON.stringify(data),
      signal: controller.signal,
    })

    if (!response.ok) {
      throw new Error("Something went wrong, please try again")
    }

    return await response.json()
  }

  static async deleteProduct(
    args: any[],
    props: any,
    controller: AbortController
  ): Promise<void> {
    const [id] = args as [number]

    const response = await fetch(`${host}/admin/products/${id}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      throw new Error("Something went wrong, please try again")
    }
  }

  static async searchProducts(
    query: string,
    controller: AbortController
  ): Promise<Product[]> {
    const response = await fetch(`${host}/admin/search-products?q=${query}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return await response.json()
  }

  static async getOrders(
    page: number,
    controller: AbortController
  ): Promise<OrderDetails[]> {
    const response = await fetch(`${host}/admin/orders?p=${page}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return await response.json()
  }

  static async getOrdersCompleted(
    controller: AbortController
  ): Promise<number> {
    const response = await fetch(`${host}/admin/orders-completed`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    const { completed } = (await response.json()) as { completed: number }
    return completed
  }

  static async searchOrders(
    query: string,
    page: number,
    controller: AbortController
  ): Promise<OrderDetails[]> {
    const response = await fetch(
      `${host}/admin/orders/search?p=${page}&q=${query}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${GDAPI.authToken}`,
        },
        signal: controller.signal,
      }
    )

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return await response.json()
  }

  static async getDailyOrderSummaries(
    date: string | undefined,
    controller: AbortController
  ): Promise<DailyRevenueSummary[]> {
    const response = await fetch(
      `${host}/admin/order-summaries/daily${date != null ? `?d=${date}` : ""}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${GDAPI.authToken}`,
        },
        signal: controller.signal,
      }
    )

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return response.json()
  }

  static async getWeeklyOrderSummaries(
    date: string | undefined,
    controller: AbortController
  ): Promise<WeeklyRevenueSummary[]> {
    const response = await fetch(
      `${host}/admin/order-summaries/weekly${date != null ? `?d=${date}` : ""}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${GDAPI.authToken}`,
        },
        signal: controller.signal,
      }
    )

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return response.json()
  }

  static async getMonthlyOrderSummaries(
    date: string | undefined,
    controller: AbortController
  ): Promise<MonthlyRevenueSummary[]> {
    const response = await fetch(
      `${host}/admin/order-summaries/monthly${
        date != null ? `?d=${date}` : ""
      }`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${GDAPI.authToken}`,
        },
        signal: controller.signal,
      }
    )

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return response.json()
  }

  static async registerMerchantAccount(
    args: any[],
    props: any,
    controller: AbortController
  ): Promise<void> {
    const { dispatch, history } = props as {
      dispatch: AppStateDispatch
      history: History
    }

    const response = await fetch(`${host}/merchants`, {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(args[0]),
      signal: controller.signal,
    })

    if (!response.ok) {
      if (response.status === 403) {
        throw new Error("Please check your account details and try again.")
      }

      const message = await response.text()
      throw new Error(message)
    }

    const body: { sid: string; t: number } = await response.json()

    dispatch({ type: "setAuthToken", authToken: body.sid })
    dispatch({ type: "setAccountType", accountType: "merchant" })

    history.push("/merchants/onboarding")
  }

  static async getOnboardingStatus(
    props: any,
    controller: AbortController
  ): Promise<MerchantOnboardingStatus> {
    const response = await fetch(`${host}/merchants/onboarding/status`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return response.json()
  }

  static async getStripeOnboardingLink(
    args: any[],
    props: any,
    controller: AbortController
  ): Promise<string> {
    const response = await fetch(`${host}/merchants/onboarding/link`, {
      method: "POST",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    const { url } = (await response.json()) as { url: string }
    return url
  }

  static async getMerchants(
    props: any,
    controller: AbortController
  ): Promise<MerchantSummary[]> {
    const response = await fetch(`${host}/admin/merchant-list`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return response.json()
  }

  static async requestPasswordReset(
    args: any[],
    props: any,
    controller: AbortController
  ): Promise<void> {
    const response = await fetch(`${host}/admin/password-reset-requests`, {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(args[0]),
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }
  }

  static async resetPassword(
    args: any[],
    props: any,
    controller: AbortController
  ): Promise<void> {
    const response = await fetch(`${host}/admin/reset-password`, {
      method: "POST",
      mode: "cors",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(args[0]),
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }
  }

  static async changePassword(
    args: any[],
    props: any,
    controller: AbortController
  ): Promise<string> {
    const response = await fetch(`${host}/admin/password`, {
      method: "PATCH",
      mode: "cors",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      body: JSON.stringify(args[0]),
      signal: controller.signal,
    })

    if (!response.ok) {
      if (response.status === 403) {
        throw new Error("Current password is incorrect.")
      }

      const message = await response.text()
      throw new Error(message)
    }

    const body: { sid: string } = await response.json()
    return body.sid
  }

  static async getShopStaff(controller: AbortController): Promise<string[]> {
    const response = await fetch(`${host}/admin/shop-staff`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return await response.json()
  }

  static async updateShopStaff(
    staffMembers: string[],
    controller: AbortController
  ) {
    const response = await fetch(`${host}/admin/shop-staff`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      body: JSON.stringify({ staffMembers }),
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }
  }

  static async getDeliveryStaff(
    controller: AbortController
  ): Promise<DeliveryStaffMember[]> {
    const response = await fetch(`${host}/admin/delivery-staff`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return await response.json()
  }

  static async addDeliveryStaff(id: number, controller: AbortController) {
    const response = await fetch(`${host}/admin/delivery-staff`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      body: JSON.stringify({ id }),
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }
  }

  static async removeDeliveryStaff(id: number, controller: AbortController) {
    const response = await fetch(`${host}/admin/delivery-staff/${id}`, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }
  }

  static async searchCustomers(
    query: string,
    controller: AbortController
  ): Promise<CustomerSearchResult[]> {
    const response = await fetch(
      `${host}/admin/delivery-staff/search?q=${query}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${GDAPI.authToken}`,
        },
        signal: controller.signal,
      }
    )

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return await response.json()
  }

  static async getOpeningHours(
    controller: AbortController
  ): Promise<TimeRange[]> {
    const response = await fetch(`${host}/admin/settings/opening-hours`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return await response.json()
  }

  static async updateOpeningHours(
    openingHours: TimeRange[],
    controller: AbortController
  ): Promise<void> {
    const response = await fetch(`${host}/admin/settings/opening-hours`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
      body: JSON.stringify(openingHours),
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }
  }

  static async getScheduledOpeningHoursChanges(
    controller: AbortController
  ): Promise<ScheduledOpeningHoursChange[]> {
    const response = await fetch(
      `${host}/admin/settings/scheduled-opening-hours-changes`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${GDAPI.authToken}`,
        },
        signal: controller.signal,
      }
    )

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return await response.json()
  }

  static async scheduleOpeningHoursChange(
    change: ScheduledOpeningHoursChange,
    controller: AbortController
  ): Promise<void> {
    const response = await fetch(
      `${host}/admin/settings/scheduled-opening-hours-changes`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${GDAPI.authToken}`,
        },
        signal: controller.signal,
        body: JSON.stringify(change),
      }
    )

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }
  }

  static async deleteScheduledOpeningHoursChange(
    date: string,
    controller: AbortController
  ): Promise<void> {
    const response = await fetch(
      `${host}/admin/settings/scheduled-opening-hours-changes/${date}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${GDAPI.authToken}`,
        },
        signal: controller.signal,
      }
    )

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }
  }

  static async getCollectionTimes(
    controller: AbortController
  ): Promise<TimeRange[]> {
    const response = await fetch(`${host}/admin/settings/collection-times`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return await response.json()
  }

  static async updateCollectionTimes(
    collectionAvailability: TimeRange[],
    controller: AbortController
  ): Promise<void> {
    const response = await fetch(`${host}/admin/settings/collection-times`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
      body: JSON.stringify(collectionAvailability),
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }
  }

  static async getDeliveryFeeSettings(
    controller: AbortController
  ): Promise<DeliveryFeeSettings> {
    const response = await fetch(`${host}/admin/settings/delivery-fee`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return response.json()
  }

  static async updateDeliveryFeeSettings(
    settings: DeliveryFeeSettings,
    controller: AbortController
  ): Promise<void> {
    const response = await fetch(`${host}/admin/settings/delivery-fee`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
      body: JSON.stringify(settings),
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }
  }

  static async issueRefund(
    orderId: string,
    refund: OrderRefund,
    controller: AbortController
  ): Promise<RefundDetails> {
    const response = await fetch(`${host}/admin/orders/${orderId}/refunds`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
      body: JSON.stringify(refund),
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return response.json()
  }

  static async getPaginatedOrderSummaries(
    cursor: string | null,
    controller: AbortController
  ): Promise<{
    results: OrderSummary[]
    nextCursor: string | null
  }> {
    const response = await fetch(
      `${host}/admin/orders${cursor ? `?c=${cursor}` : ``}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${GDAPI.authToken}`,
        },
        signal: controller.signal,
      }
    )

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return response.json()
  }

  static async searchPaginatedOrderSummaries(
    query: string,
    cursor: string | null,
    controller: AbortController
  ): Promise<{
    results: OrderSummary[]
    nextCursor: string | null
  }> {
    const response = await fetch(
      `${host}/admin/orders/search?q=${query}${cursor ? `&c=${cursor}` : ``}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${GDAPI.authToken}`,
        },
        signal: controller.signal,
      }
    )

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return response.json()
  }

  static async getOrderDetails(
    id: string,
    controller: AbortController
  ): Promise<OrderDetails> {
    const response = await fetch(`${host}/admin/orders/${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${GDAPI.authToken}`,
      },
      signal: controller.signal,
    })

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    return response.json()
  }

  static async getCashPaymentEnabled(
    controller: AbortController
  ): Promise<boolean> {
    const response = await fetch(
      `${host}/admin/settings/cash-payment-enabled`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${GDAPI.authToken}`,
        },
        signal: controller.signal,
      }
    )

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }

    const { isCashPaymentEnabled } = (await response.json()) as {
      isCashPaymentEnabled: boolean
    }
    return isCashPaymentEnabled
  }

  static async updateCashPaymentEnabled(
    isCashPaymentEnabled: boolean,
    controller: AbortController
  ): Promise<void> {
    const response = await fetch(
      `${host}/admin/settings/cash-payment-enabled`,
      {
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${GDAPI.authToken}`,
        },
        signal: controller.signal,
        body: JSON.stringify({ isCashPaymentEnabled }),
      }
    )

    if (!response.ok) {
      const message = await response.text()
      throw new Error(message)
    }
  }
}
