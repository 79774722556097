import _ from "lodash"
import { useEffect, useMemo, useRef } from "react"
import * as DateFns from "date-fns"

import {
  DailyRevenueSummary,
  MonthlyRevenueSummary,
  RevenueSummary,
  WeeklyRevenueSummary,
} from "../../../lib/GDAPI"

interface SummariesListProps {
  summaries: RevenueSummary[]
  fetchMore: () => any
  fetchedAll: boolean
}

const observerOptions: IntersectionObserverInit = {
  threshold: 0.5,
}

export function SummariesList(props: SummariesListProps) {
  const { summaries, fetchMore, fetchedAll } = props
  const fetchMoreTriggerRef = useRef<HTMLDivElement>(null)

  const summaryItems = useMemo(() => {
    return summaries.map((summary, index) => {
      const isDaily = isDailyRevenueSummary(summary)

      return (
        <SummaryItem
          key={isDaily ? (summary as DailyRevenueSummary).date : index}
          summary={summary}
        />
      )
    })
  }, [summaries])

  useEffect(() => {
    const observer = new IntersectionObserver((entities) => {
      if (!entities[0].isIntersecting) {
        return
      }

      fetchMore()
    }, observerOptions)

    if (fetchMoreTriggerRef.current != null) {
      observer.observe(fetchMoreTriggerRef.current)
    }

    return () => {
      observer.disconnect()
    }
  }, [fetchMore])

  return (
    <div className="flex flex-col flex-1 p-2 md:p-4 items-center overflow-y-auto">
      {summaryItems}

      <div
        ref={fetchMoreTriggerRef}
        className={`
          flex flex-col items-center align-center
          p-4
          text-base text-gray-500
        `}
      >
        {fetchedAll ? "No more results." : "Loading..."}
      </div>
    </div>
  )
}

interface SummaryItemProps {
  summary: RevenueSummary
}

export function SummaryItem(props: SummaryItemProps) {
  const { summary } = props
  const isDaily = isDailyRevenueSummary(summary)
  const isWeekly = isWeeklyRevenueSummary(summary)
  const isMonthly = isMonthlyRevenueSummary(summary)

  return (
    <div className="flex flex-col bg-white border-gray-300 py-4 mb-2 md:mb-4 shadow-sm md:shadow rounded w-full max-w-sm">
      <div className="px-4">
        <div className="flex flex-row items-center text-base text-brand-600 font-medium">
          {isDaily && (
            <>
              <div className="flex-1">
                {DateFns.format(
                  new Date((summary as DailyRevenueSummary).date),
                  "EEE do MMM"
                )}
              </div>
              <div className="text-sm font-medium text-gray-400">
                {DateFns.format(
                  new Date((summary as DailyRevenueSummary).date),
                  "d/M/yy"
                )}
              </div>
            </>
          )}
          {isWeekly && (
            <>
              <div className="flex-1">
                {DateFns.format(
                  new Date((summary as WeeklyRevenueSummary).start),
                  "EEE do MMM"
                )}{" "}
                -{" "}
                {DateFns.format(
                  new Date((summary as WeeklyRevenueSummary).end),
                  "EEE do MMM"
                )}
              </div>
            </>
          )}
          {isMonthly && (
            <>
              <div className="flex-1">
                {DateFns.format(
                  new Date((summary as MonthlyRevenueSummary).month),
                  "MMMM yyyy"
                )}
              </div>
            </>
          )}
        </div>
        <div className="flex flex-row items-center text-base font-medium text-gray-800 mt-2">
          Vivo Spencer Road
        </div>

        <div className="flex flex-row items-center text-base text-gray-800">
          <div className="flex-1">
            {summary.vivoSpencerRoad.orders.count === 1
              ? "1 order"
              : `${summary.vivoSpencerRoad.orders.count} orders`}
          </div>
          <div>£{(summary.vivoSpencerRoad.orders.total / 100).toFixed(2)}</div>
        </div>

        {summary.vivoSpencerRoad.orders.cardCount != null &&
          summary.vivoSpencerRoad.orders.cardTotal != null &&
          summary.vivoSpencerRoad.orders.cashCount != null &&
          summary.vivoSpencerRoad.orders.cashTotal != null && (
            <>
              <div className="flex flex-row items-center text-base text-gray-500 ml-4">
                <div className="flex-1">
                  {summary.vivoSpencerRoad.orders.cardCount === 1
                    ? "1 card order"
                    : `${summary.vivoSpencerRoad.orders.cardCount} card orders`}
                </div>

                <div>
                  £{(summary.vivoSpencerRoad.orders.cardTotal / 100).toFixed(2)}
                </div>
              </div>

              <div className="flex flex-row items-center text-base text-gray-500 ml-4">
                <div className="flex-1">
                  {summary.vivoSpencerRoad.orders.cashCount === 1
                    ? "1 cash order"
                    : `${summary.vivoSpencerRoad.orders.cashCount} cash orders`}
                </div>

                <div>
                  £{(summary.vivoSpencerRoad.orders.cashTotal / 100).toFixed(2)}
                </div>
              </div>
            </>
          )}

        {summary.vivoSpencerRoad.discounts.total > 0 && (
          <div className="flex flex-row items-center text-base text-gray-800">
            <div className="flex-1">
              {summary.vivoSpencerRoad.discounts.count === 1
                ? "1 discount"
                : `${summary.vivoSpencerRoad.discounts.count} discounts`}
            </div>

            <div>
              -£{(summary.vivoSpencerRoad.discounts.total / 100).toFixed(2)}
            </div>
          </div>
        )}

        {summary.vivoSpencerRoad.refunds.total > 0 && (
          <div className="flex flex-row items-center text-base text-red-600">
            <div className="flex-1">
              {summary.vivoSpencerRoad.refunds.count === 1
                ? "1 refund"
                : `${summary.vivoSpencerRoad.refunds.count} refunds`}
            </div>

            <div>
              -£{(summary.vivoSpencerRoad.refunds.total / 100).toFixed(2)}
            </div>
          </div>
        )}

        <div className="flex flex-row items-center text-base text-blue-600">
          <div className="flex-1">Stripe fees</div>

          <div>-£{(summary.vivoSpencerRoad.fees / 100).toFixed(2)}</div>
        </div>

        {summary.ionaHouse.orders.count > 0 && (
          <>
            <div className="flex flex-row items-center text-base font-medium text-gray-800 mt-2">
              Iona House Off Licence
            </div>

            <div className="flex flex-row items-center text-base text-gray-800">
              <div className="flex-1">
                {summary.ionaHouse.orders.count === 1
                  ? "1 order"
                  : `${summary.ionaHouse.orders.count} orders`}
              </div>

              <div>£{(summary.ionaHouse.orders.total / 100).toFixed(2)}</div>
            </div>

            {summary.ionaHouse.orders.cardCount != null &&
              summary.ionaHouse.orders.cardTotal != null &&
              summary.ionaHouse.orders.cashCount != null &&
              summary.ionaHouse.orders.cashTotal != null && (
                <>
                  <div className="flex flex-row items-center text-base text-gray-500 ml-4">
                    <div className="flex-1">
                      {summary.ionaHouse.orders.cardCount === 1
                        ? "1 card order"
                        : `${summary.ionaHouse.orders.cardCount} card orders`}
                    </div>

                    <div>
                      £{(summary.ionaHouse.orders.cardTotal / 100).toFixed(2)}
                    </div>
                  </div>

                  <div className="flex flex-row items-center text-base text-gray-500 ml-4">
                    <div className="flex-1">
                      {summary.ionaHouse.orders.cashCount === 1
                        ? "1 cash order"
                        : `${summary.ionaHouse.orders.cashCount} cash orders`}
                    </div>

                    <div>
                      £{(summary.ionaHouse.orders.cashTotal / 100).toFixed(2)}
                    </div>
                  </div>
                </>
              )}

            {summary.ionaHouse.discounts.total > 0 && (
              <div className="flex flex-row items-center text-base text-purple-600">
                <div className="flex-1">
                  {summary.ionaHouse.discounts.count === 1
                    ? "1 discount"
                    : `${summary.ionaHouse.discounts.count} discounts`}
                </div>

                <div>
                  -£{(summary.ionaHouse.discounts.total / 100).toFixed(2)}
                </div>
              </div>
            )}

            {summary.ionaHouse.refunds.total > 0 && (
              <div className="flex flex-row items-center text-base text-red-600">
                <div className="flex-1">
                  {summary.ionaHouse.refunds.count === 1
                    ? "1 refund"
                    : `${summary.ionaHouse.refunds.count} refunds`}
                </div>

                <div>
                  -£{(summary.ionaHouse.refunds.total / 100).toFixed(2)}
                </div>
              </div>
            )}

            <div className="flex flex-row items-center text-base text-blue-600">
              <div className="flex-1">Stripe fees</div>

              <div>-£{(summary.ionaHouse.fees / 100).toFixed(2)}</div>
            </div>
          </>
        )}
        {summary.donations.count > 0 ? (
          <>
            <div className="flex flex-row items-center text-base font-medium text-gray-800 mt-2">
              Foyle Hospice
            </div>

            <div className="flex flex-row items-center text-base text-gray-800">
              <div className="flex-1">
                {summary.donations.count === 1
                  ? "1 donation"
                  : `${summary.donations.count} donations`}
              </div>

              <div>£{(summary.donations.total / 100).toFixed(2)}</div>
            </div>
          </>
        ) : null}
      </div>

      <div className="mx-4 mt-2">
        <div className="flex flex-row items-center text-base font-medium text-green-600 py-1">
          Deliveries
        </div>

        {summary.drivers.map((driver) => {
          return (
            <div
              key={driver.name}
              className="flex flex-row items-center text-base text-gray-700 border-gray-200 border-t py-1"
            >
              <div className="flex-1 text-left">{driver.name}</div>

              <div className="flex-1 text-center">
                {driver.deliveries.count}
              </div>

              <div className="flex-1 text-right">
                £{(driver.deliveries.total / 100).toFixed(2)}
              </div>
            </div>
          )
        })}
        {summary.drivers.length > 1 && (
          <div className="flex flex-row items-center text-base text-gray-700 border-gray-200 border-t py-1">
            <div className="flex-1" />

            <div className="flex-1 text-center">{summary.deliveries.count}</div>

            <div className="flex-1 text-right">
              £{(summary.deliveries.total / 100).toFixed(2)}
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

function isDailyRevenueSummary(
  summary: RevenueSummary
): summary is DailyRevenueSummary {
  return _.has(summary, "date")
}

function isWeeklyRevenueSummary(
  summary: RevenueSummary
): summary is WeeklyRevenueSummary {
  return _.has(summary, "start") && _.has(summary, "end")
}

function isMonthlyRevenueSummary(
  summary: RevenueSummary
): summary is MonthlyRevenueSummary {
  return _.has(summary, "month")
}
